import React from 'react'
import seqStore from '../../store/seqStore'

export default function Transpose() {
    const transpose = seqStore((state) => state.sequenceSettings.transpose)
    const setTranspose = seqStore((state) => state.setTranspose)

    // TRANSPOSE CONTROLS
    const handleTranspose = (e) => {
        const value = e.target.value
        setTranspose(value)
    }

    const resetTranspose = () => {
        setTranspose(0)
    }
    
    return (
        <div id='transposeInput'>
            <label htmlFor="root note"><p>Transpose <span>{transpose}</span></p></label>
            <input id='enabledInput' type="range" min={-12} max={12} step='1' value={transpose} onChange={e => handleTranspose(e)} onDoubleClick={resetTranspose}/>
        </div>    
    )
}
