import React from 'react'
import seqStore from '../../store/seqStore'

export default function MidiChannel() {

    const midiCh = seqStore((state) => state.sequenceSettings.midiCh)
    const setMidiCh = seqStore((state) => state.setMidiCh)

    // MIDI CHANNEL CONTROLS
    const handleMidiCh = (e) => {
        const value = e.target.value
        setMidiCh(value)
    }

    const resetMidiCh = (e) => {
        setMidiCh(0)
    }
    
    return (
        <div id='midiChInput'>
            <label htmlFor="root note">Midi Channel <span>{Number(midiCh) + 1}</span></label>
            <input id='enabledInput' type="range" min={0} max={15} step='1' value={midiCh} onChange={e => handleMidiCh(e)} onDoubleClick={resetMidiCh}/>
        </div>
    )
}
