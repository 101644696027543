import React, {useEffect, useState} from 'react'
import midiToNote from '../utils/midiToNote'
import seqStore from '../store/seqStore'
import AddRoundedIcon from '@mui/icons-material/AddRounded'
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded'

export default function DisplayNote({ MIDIOutput, stepDuration }) {
    // store states
    const sequence = seqStore((state) => state.sequence)
    const sequenceQ = seqStore((state) => state.sequence)
    const length = Number(seqStore((state) => state.sequenceSettings.length))
    const pattern = seqStore((state) => state.sequenceSettings.pattern)
    const playhead = Number(seqStore((state) => state.sequenceSettings.playhead))
    const midiCh = Number(seqStore((state) => state.sequenceSettings.midiCh))
    const transSemi = Number(seqStore((state) => state.sequenceSettings.transpose))
    const transOctave = Number(seqStore((state) => state.sequenceSettings.octave))
    const setPlayhead = Number(seqStore((state) => state.setPlayhead))
    const scale = Number(seqStore((state) => state.sequenceSettings.scale))

    // useState states
    const [displayNote, setDisplayNote] = useState(null)

    // handle prev button
    const handlePrev = () => {
        // build the midi message
        const prevPlayhead = (playhead - 1 + length) % length
        const messageON = '0x9'+ midiCh
        const messageOFF = '0x8'+ midiCh
        let note
        if (scale !== 0) {
            note = sequenceQ[prevPlayhead] + (transOctave * 12) + transSemi
        } else {
            note = sequence[prevPlayhead] + (transOctave * 12) + transSemi
        }
        const velocity = 0x7f

        // send the midi message
        MIDIOutput.send([messageON, note, velocity])
        setPlayhead(prevPlayhead)
        MIDIOutput.send([messageOFF, note, 0], stepDuration) //step duration timeout
    }
    
    // handle next button
    const handleNext = () => {
        // build the midi message
        const nextPlayhead = (playhead + 1) % length
        const messageON = '0x9'+ midiCh
        const messageOFF = '0x8'+ midiCh
        let note
        if (scale !== 0) {
            note = sequenceQ[nextPlayhead] + (transOctave * 12) + transSemi
        } else {
            note = sequence[nextPlayhead] + (transOctave * 12) + transSemi
        }
        const velocity = 0x7f

        // send the midi message
        MIDIOutput.send([messageON, note, velocity])
        setPlayhead(nextPlayhead)
        MIDIOutput.send([messageOFF, note, 0], stepDuration) //step duration timeout
    }

    // handle arrows keyboard
    const handleArrows = (e) => {
        if (e.key === 'ArrowLeft') {
            handlePrev()    
        } else if (e.key === 'ArrowRight') {
            handleNext()  
        }
    }

    //handle KeyDown
    useEffect(() => {
        const handleKeyDown = (e) => {
            handleArrows(e)
        }
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [handleArrows])

    // predict next note to display
    useEffect(()=> {
        if (playhead === -1) {
            setDisplayNote("stop")
        }
        else if (pattern[playhead] === 1) {
            let noteToDisplay
            if (scale !== 0) {
                noteToDisplay = midiToNote(sequenceQ[playhead] + (transOctave * 12) + transSemi)
            } else {
                noteToDisplay = midiToNote(sequence[playhead] + (transOctave * 12) + transSemi)
            }
            // if (noteToDisplay.length === 2) {
            //     const note = noteToDisplay[0]
            //     const octave = noteToDisplay[1]
            //     noteToDisplay = note + "_" + octave + " "
            // } else if (noteToDisplay.length === 3) {
            //     noteToDisplay = noteToDisplay + " " 
            // }
            setDisplayNote(noteToDisplay)
        } else {
            return // don't display the non active note but the previous active note
        }
    },[playhead, pattern, displayNote, sequence])

    return (
        <div id='displayWrapper' onKeyDown={e => handleArrows}>
            <div id='displayCounterWrapper'>
                <RemoveRoundedIcon id='minusIcon' onClick={handlePrev} />
                <p id='currentNote'>{displayNote}</p>
                <AddRoundedIcon id='plusIcon' onClick={handleNext} />
            </div>
        </div>
    )
}
