import React from 'react'
import seqStore from '../../store/seqStore'

export default function Pulses() {
    const pulses = seqStore((state) => state.sequenceSettings.pulses)
    const setPulses = seqStore((state) => state.setPulses)
    const length = seqStore((state) => state.sequenceSettings.length)

    // PULSES CONTROLS
    const handlePulses = (e) => {
        const value = e.target.value
        setPulses(value)
    }

    const resetPulses = () => {
        setPulses(length)
    }
    
    return (
        <div id='pulsesInput'>
            <label htmlFor="root note">Pulses <span>{pulses}</span></label>
            <input id='enabledInput' type="range" min={1} max={length} step='1' value={pulses} onChange={e => handlePulses(e)} onDoubleClick={resetPulses}/>
        </div>
    )
}
