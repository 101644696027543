import React from 'react'
import seqStore from '../../store/seqStore'

export default function Offset() {
    const offset = seqStore((state) => state.sequenceSettings.offset)
    const setOffset = seqStore((state) => state.setOffset)
    const length = seqStore((state) => state.sequenceSettings.length)

    // OFFSET CONTROLS
    const handleOffset = (e) => {
        const value = 0 - e.target.value
        setOffset(value)
    }

    const resetOffset = () => {
        setOffset(0)
        }
    
    return (
        <div id='offsetInput'>
            <label htmlFor="root note">Offset <span>{offset}</span></label>
            <input id='enabledInput' type="range" min={-(length - 1)} max={length - 1} step='1' value={0 - offset} onChange={e => handleOffset(e)} onDoubleClick={resetOffset}/>
        </div>
    )
}
