import seqStore from '../../store/seqStore'

export default function GateLength() {
    const gateLength = Number(seqStore((state) => state.sequenceSettings.gateLength))
    const setGateLength = seqStore((state) => state.setGateLength)

    // GATE LENGTH CONTROLS
    const handleGateLength = (e) => {
        const value = e.target.value
        setGateLength(value)
    }

    const resetGateLength = () => {
        setGateLength(50)
    }

    return (
        <div id='gateLengthInput'>
            <label htmlFor="root note">Gate <span>{gateLength+'%'}</span></label>
            <input id='enabledInput' type="range" min={5} max={95} step='5' value={gateLength} onChange={e => handleGateLength(e)} onDoubleClick={resetGateLength}/>
        </div>
    )
}
