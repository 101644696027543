import { useEffect, useState } from 'react'

export default function MidiOutSelect({ onSelect }) {
  const [midiOutDevices, setMidiOutDevices] = useState([])
  const [deviceSelected, setDeviceSelected] = useState(null)

  useEffect(() => {
    const init = async () => {
      try {
        const storedMidiDevice = sessionStorage.getItem('storedMidiDevice')
        const midiAccess = await navigator.requestMIDIAccess()
        const outDevices = Array.from(midiAccess.outputs.values())
        setMidiOutDevices(outDevices)
        
        if (storedMidiDevice) {
          const foundDevice = outDevices.find(device => device.id === storedMidiDevice)
          if (foundDevice) {
            setDeviceSelected(foundDevice)
            onSelect(foundDevice)
          }
        }
      } catch (error) {
        console.log('Failed to access MIDI devices:', error)
      }
    }

    init()
  }, [onSelect])

  const handleChange = (event) => {
    let selectedDevice
    if (event.target.value === "no-device") {
      selectedDevice = event.target.value
    } else {
      const selectedDeviceId = event.target.value
      selectedDevice = midiOutDevices.find(device => device.id === selectedDeviceId)
    }
    setDeviceSelected(selectedDevice)
    onSelect(selectedDevice)
  }

  return (
    <div id='MidiOutputWrapper'>
      <label htmlFor="midiOutputLabel"><p>Midi Out</p></label>
      <select id='selectMidiDevice' value={deviceSelected ? deviceSelected.id : "no-device"} onChange={handleChange}>
        <option value="no-device">No MIDI Device</option>
        {midiOutDevices.map(device => (
          <option key={device.id} value={device.id}>{device.name}</option>
        ))}
      </select>
    </div>
  )
}