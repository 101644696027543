// euclidean algorithm
export default function genEuclidean(pulses, length, offset) {
    let previousPulsePosition = null
    const pattern = []
  
    // Generate the Euclidean rhythm pattern
    for (let i = 0; i < length; i++) {
      const pulsePosition = Math.floor((pulses / length) * i)
      pattern.push(pulsePosition === previousPulsePosition ? 0 : 1)
      previousPulsePosition = pulsePosition
    }
  
    // Rotate the pattern based on the offset
    const offsetPattern = [...pattern.slice(offset % length), ...pattern.slice(0, offset % length)]
    return offsetPattern
  }
