import seqStore from "../../store/seqStore"

export default function Scales() {
    const scale = seqStore((state) => state.sequenceSettings.scale)
    const setScale = seqStore((state) => state.setScale)
    const scaleNames = ['chroma', 'major', 'minor', 'phrygian', 'lydian', 'mixo', 'locrian']

    const handleScale = (e) => {
        const value = e.target.value
        setScale(Number(value))
    }

    const resetScale = () => {
        setScale(Number(0))
    }

    return (
        <div id='scaleInput'>
            <label htmlFor="root note"><p>Scale <span>{scaleNames[scale]}</span></p></label>
            <input id='enabledInput' type="range" min={0} max={6} step='1' value={scale} onChange={e => handleScale(e)} onDoubleClick={resetScale}/>
        </div>
    )
}
