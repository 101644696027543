import React, { useEffect, useState } from 'react'
import seqStore from '../store/seqStore'

export default function DentalSequencer({ playState, MIDIOutput, gateLength, isAnimation }) {
    const length = seqStore((state) => state.sequenceSettings.length)
    const sequence = seqStore((state) => state.sequence)
    const sequenceQ = seqStore((state) => state.sequenceQ)
    const scale = seqStore((state) => state.sequenceSettings.scale)
    const seqRatchet = seqStore((state) => state.sequenceSettings.seqRatchet)
    const setSeqRatchet = seqStore((state) => state.setSeqRatchet)
    const midiCh = seqStore((state) => state.sequenceSettings.midiCh)
    const pattern = seqStore((state) => state.sequenceSettings.pattern)
    const playhead = seqStore((state) => state.sequenceSettings.playhead)
    const setPlayhead = seqStore((state) => state.setPlayhead)
    const transSemi = seqStore((state) => state.sequenceSettings.transpose)
    const transOctave = seqStore((state) => state.sequenceSettings.octave)
    
    const [teeth, setTeeth] = useState([])
    //const [seqRatchet, setSeqRatchet] = useState(Array(32).fill(false))

    const sendNote = (msg, velocity, toothNumber, timeout) => {
        if (MIDIOutput) {
            setPlayhead(toothNumber)
            let note
            if (scale !== 0) {
                note = sequenceQ[toothNumber] + (transOctave * 12) + transSemi
            } else {
                note = sequence[toothNumber] + (transOctave * 12) + transSemi
            }
            MIDIOutput.send([msg + midiCh, note, velocity], performance.now() - timeout)
        }
    }

    // mouth builder
    useEffect(() => {
        const newTeeth = []

        for (let i = 0; i < length; i++) {
            const toothNumber = i
            const isActive = pattern[i] === 1

            newTeeth.push(
                <div
                    className='tooth'
                    key={i}
                    style={{
                        backgroundColor: isActive ? (playhead === i ? '#FFB72C' : '#DFDBCE') : 'transparent',
                    }}
                    onMouseDown={playState !== "play"? () => sendNote('0x9', 0x7f, toothNumber, 0) : null}
                    onMouseUp={playState !== "play"? () => sendNote('0x8', 0x00, toothNumber, gateLength) : null}
                    onDoubleClick={() => {
                        const updatedRatchet = (seqRatchet[toothNumber] + 1) % 5 // Increment ratchet and ensure it cycles from 0 to 4
                        setSeqRatchet(toothNumber, updatedRatchet) // Toggle seqRatchet
                        //console.log(updatedRatchet)
                    }}
                >
                {seqRatchet[toothNumber] !== 0 && isActive ? 
                    <div id={`ratchetCircle_${seqRatchet[toothNumber]}`} className='ratchetCircle'></div> 
                : 
                    null}
                </div>
            )
        }

        setTeeth(newTeeth)
    }, [length, sequence, midiCh, pattern, playhead, seqRatchet])

    return (
        <section id='dentalSequencer'>
            <div
                id={
                    !isAnimation
                        ? 'mouthStopAnimation'
                        : playState === 'play'
                        ? 'mouthPlayAnimation'
                        : playState === 'pause'
                        ? 'mouthPauseAnimation'
                        : 'mouthStopAnimation'
                }
            >
                <div id='topMouth'>{teeth.slice(0, length / 2)}</div>
                <div id='bottomMouth'>{teeth.slice(length / 2)}</div>
                <div id='tongue'></div>
            </div>
        </section>
    )
}
