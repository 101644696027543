let pingpongMode = "forward"

export default function playheadCounter(playhead, direction, length) {
    let nextPlayhead

    switch (direction) {
        case 0: // Move forward
            nextPlayhead = (playhead + 1) % length
            break
        case 1: // Move backward
            nextPlayhead = (playhead - 1 + length) % length
            break
        case 2: // Ping pong mode
            if (playhead === length - 1 && pingpongMode === 'forward') {
                pingpongMode = 'backward'
            } else if (playhead === 0 && pingpongMode === 'backward') {
                pingpongMode = 'forward'
            }
            nextPlayhead = (pingpongMode === 'forward') ? (playhead + 1) % length : (playhead - 1 + length) % length
            break
        case 3: // Move randomly
            nextPlayhead = Math.floor(Math.random() * length)
            break
        default:
            nextPlayhead = playhead // Default to current playhead if direction is not recognized
    }
    
    return nextPlayhead
}