import React from 'react'
import seqStore from '../../store/seqStore'

export default function Octave() {
    const sync = seqStore((state) => state.sequenceSettings.sync)
    const octave = seqStore((state) => state.sequenceSettings.octave)
    const setOctave = seqStore((state) => state.setOctave)

    // OCTAVE CONTROLS
    const handleOctave = (e) => {
        const value = e.target.value
        setOctave(value)
    }

    const resetOctave = () => {
        setOctave(0)
    }
    

    return (
        <div id='octaveInput'>
            <label htmlFor="root note" id={sync? 'disabledLabel' : null} ><p>Octave <span>{octave}</span></p></label>
            <input id={sync? 'disabled' :  'enabledInput'} type="range" min={-3} max={3} step='1' value={octave} onChange={e => handleOctave(e)} onDoubleClick={resetOctave} disabled={sync}/>
        </div>
    )
}
