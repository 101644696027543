import { create } from 'zustand'

const seq_alternative = [
  {
      "note": "C",
      "octave": 7,
  },
  {
      "note": "D#",
      "octave": 7,
  },
  {
      "note": "D",
      "octave": 7,
  },
  {
      "note": "A#",
      "octave": 6,
  },
  {
      "note": "F",
      "octave": 6,
  },
  {
      "note": "G#",
      "octave": 6,
  },
  {
      "note": "G",
      "octave": 6,
  },
  {
      "note": "C",
      "octave": 6,
  },
  {
      "note": "D#",
      "octave": 6,
  },
  {
      "note": "D",
      "octave": 6,
  },
  {
      "note": "G#",
      "octave": 6,
  },
  {
      "note": "G",
      "octave": 6,
  },
  {
      "note": "C",
      "octave": 7,
  },
  {
      "note": "B",
      "octave": 6,
  },
  {
      "note": "C",
      "octave": 7,
  },
  {
      "note": "D",
      "octave": 7,
  },
  {
      "note": "G",
      "octave": 4,
  },
  {
      "note": "D#",
      "octave": 4,
  },
  {
      "note": "C",
      "octave": 4,
  },
  {
      "note": "G",
      "octave": 5,
  },
  {
      "note": "A#",
      "octave": 5,
  },
  {
      "note": "D#",
      "octave": 4,
  },
  {
      "note": "C",
      "octave": 5,
  },
  {
      "note": "G",
      "octave": 5,
  },
  {
      "note": "A#",
      "octave": 4,
  },
  {
      "note": "C",
      "octave": 4,
  },
  {
      "note": "D#",
      "octave": 4,
  },
  {
      "note": "G",
      "octave": 5,
  },
  {
      "note": "A#",
      "octave": 5,
  },
  {
      "note": "D#",
      "octave": 4,
  },
  {
      "note": "C",
      "octave": 5,
  },
  {
      "note": "G",
      "octave": 5,
  }
]

const seq_midi_alternative = [
  103, 99, 98, 94, 89, 86, 85, 84, 82, 81, 86, 85, 96, 95, 103, 98, 67, 63, 60, 79, 74, 63, 72, 79, 70, 60, 63, 79, 74, 63, 72, 79
];

const seq_original = [
  {
    note: 'A#',
    octave: 4,
  },
  {
    note: 'C',
    octave: 4,
  },
  {
    note: 'D#',
    octave: 4,
  },
  {
    note: 'G',
    octave: 5,
  },
  {
    note: 'A#',
    octave: 5,
  },
  {
    note: 'D#',
    octave: 4,
  },
  {
    note: 'C',
    octave: 3,
  },
  {
    note: 'G',
    octave: 3,
  },
  {
    note: 'A#',
    octave: 3,
  },
  {
    note: 'C',
    octave: 4,
  },
  {
    note: 'D#',
    octave: 4,
  },
  {
    note: 'G',
    octave: 5,
  },
  {
    note: 'C',
    octave: 5,
  },
  {
    note: 'D#',
    octave: 4,
  },
  {
    note: 'C',
    octave: 5,
  },
  {
    note: 'A#',
    octave: 4,
  },


  {
    note: 'G',
    octave: 4,
  },
  {
    note: 'D#',
    octave: 4,
  },
  {
    note: 'C',
    octave: 4,
  },
  {
    note: 'G',
    octave: 5,
  },
  {
    note: 'A#',
    octave: 5,
  },
  {
    note: 'D#',
    octave: 4,
  },
  {
    note: 'C',
    octave: 5,
  },
  {
    note: 'G',
    octave: 5,
  },
  {
    note: 'A#',
    octave: 4,
  },
  {
    note: 'C',
    octave: 4,
  },
  {
    note: 'D#',
    octave: 4,
  },
  {
    note: 'G',
    octave: 5,
  },
  {
    note: 'A#',
    octave: 5,
  },
  {
    note: 'D#',
    octave: 4,
  },
  {
    note: 'C',
    octave: 5,
  },
  {
    note: 'G',
    octave: 5,
  },
]

const seq_original_midi = [70, 60, 63, 79, 82, 63, 48, 55, 58, 60, 63, 79, 84, 63, 84, 70, 70, 60, 63, 79, 82, 63, 48, 55, 58, 60, 63, 79, 84, 63, 84, 70]

const seq_2 = [
  {
    "note":"C",
    "octave":4
  },
  {
    "note":"D#",
    "octave":5
  },
  {
    "note":"D",
    "octave":5
  },
  {
    "note":"G#",
    "octave":4
  },
  {
    "note":"F",
    "octave":5
  },
  {
    "note":"D#",
    "octave":5
  },
  {
    "note":"D#",
    "octave":4
  },
  {
    "note":"F",
    "octave":5
  },
  {
    "note":"G",
    "octave":5
  },
  {
    "note":"A#",
    "octave":4
  },
  {
    "note":"D#",
    "octave":5
  },
  {
    "note":"F",
    "octave":5
  },
  {
    "note":"G#",
    "octave":4
  },
  {
    "note":"F",
    "octave":5
  },
  {
    "note":"D#",
    "octave":5
  },
  {
    "note":"D",
    "octave":5
  },
  {
    "note":"G",
    "octave":4
  },
  {
    "note":"D#",
    "octave":4
  },
  {
    "note":"C",
    "octave":4
  },
  {
    "note":"G",
    "octave":5
  },
  {
    "note":"A#",
    "octave":5
  },
  {
    "note":"D#",
    "octave":4
  },
  {
    "note":"C",
    "octave":5
  },
  {
    "note":"G",
    "octave":5
  },
  {
    "note":"A#",
    "octave":4
  },
  {
    "note":"C",
    "octave":4
  },
  {
    "note":"D#",
    "octave":4
  },
  {
    "note":"G",
    "octave":5
  },
  {
    "note":"A#",
    "octave":5
  },
  {
    "note":"D#",
    "octave":4
  },
  {
    "note":"C",
    "octave":5
  },
  {
    "note":"G",
    "octave":5
  }
]

const seq_midi_original = [
  70, 72, 63, 79, 74, 63, 48, 55, 58, 60, 63, 79, 72, 63, 72, 70, 67, 63, 60, 79, 74, 63, 72, 79, 70, 60, 63, 79, 74, 63, 72, 79
];


const seqStore = create((set) => ({
  
  // SEQUENCE
  sequence: seq_original_midi,
  sequenceQ: seq_original_midi,

  setNote: (index, newNote) => 
    set((state) => ({
      sequence: state.sequence.map((note, i) => (i === index ? newNote : note)),
    })),

  setQNote: (index, newNote) => 
    set((state) => ({
        sequenceQ: state.sequenceQ.map((note, i) => (i === index ? newNote : note)),
    })),
  

  // SEQUENCE SETTINGS
  sequenceSettings: {
    length: 16, 
    transpose: 0, 
    octave: 0, 
    pulses: 16, 
    offset: 0, 
    gateLength: 50,
    division: 4,
    randOctave: false,
    direction: 0,
    root: 0,
    scale: 0,
    midiOut: true, 
    sync: false,
    midiCh: 0,
    playhead: -1,
    pattern: [1,1,1,1, 1,1,1,1, 1,1,1,1, 1,1,1,1,1,1,1,1, 1,1,1,1, 1,1,1,1, 1,1,1,1],
    seqRatchet: [0,0,0,0, 0,0,0,0, 0,0,0,0, 0,0,0,0, 0,0,0,0, 0,0,0,0, 0,0,0,0, 0,0,0,0,],
    ratchet: 0,
  },
  
  setLength: (value) =>
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, length: value },
    })),
  
  setGateLength: (value) =>
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, gateLength: value },
    })),
    
  setTranspose: (value) => 
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, transpose: value },
    })),
    
  setOctave: (value) => 
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, octave: value },
    })),

  setPulses: (value) => 
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, pulses: value },
    })),

  setOffset: (value) => 
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, offset: value },
    })),

  setDivision: (value) =>  //0=whole, 1=half, 2=4th, 3=8th, 4=16th, 5=32th, 6=64th
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, division: value },
    })),

  setDirection: (value) =>  // 0='forward', 1='backward', 2='random'
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, direction: value },
    })),

  setRatchet: (value) =>  // 0='none
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, ratchet: value },
    })),

  setMidiCh: (value) =>  
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, midiCh: value },
    })),

  setMidiOut: (value) =>
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, isMidiOut: value },
    })),

  setSync: (value) =>
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, sync: value },
    })),

  setRandOctave: (value) =>
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, randOctave: value },
    })),

  setPlayhead: (value) =>
    set((state) => ({
      ...state, // Preserve other properties
      sequenceSettings: { ...state.sequenceSettings, playhead: value },
    })),

  setPattern: (value) =>
    set((state) => ({
      ...state,
      sequenceSettings: {...state.sequenceSettings, pattern: value}
    })),

  setSeqRatchet: (index, newValue) =>
    set((state) => ({
      sequenceSettings: {
        ...state.sequenceSettings,
        seqRatchet: state.sequenceSettings.seqRatchet.map((oldValue, i) => 
          i === index ? newValue : oldValue
        ),
      },
      
    })
  ),

  setScale: (value) =>
    set((state) => ({
      ...state,
      sequenceSettings: {...state.sequenceSettings, scale: value}
    })),

  setRoot: (value) =>
    set((state) => ({
      ...state,
      sequenceSettings: {...state.sequenceSettings, root: value}
    })),
  
  // TEMPO
  tempo: Number(120),
  
  setTempo: (value) => {
    set((state) => ({
      tempo: value
    }))
  },

  setSequenceSettings: (value) => {
    set((state) => ({
      sequenceSettings: {...state.sequenceSettings, ...value}
    }))
  }, 

  setSequence: (index, newNote) => 
    set((state) => ({
      sequence: state.sequence.map((note, i) => (i === index ? newNote : note)),
    })),

  setSequenceQ: (index, newNote) => {
    set((state) => ({
      sequenceQ: state.sequenceQ.map((note, i) => (i === index ? newNote : note)),
    }))
  }, 

}))



export default seqStore
