import React from 'react'

export default function PlayButton({handlePlay, handleSpaceKey, playState }) {
    return (
        <div onClick={handlePlay} onKeyUp={handleSpaceKey} tabIndex={0}>
            {playState !== "pause" &&
                <svg id='playIcon'width="40" height="46" viewBox="0 0 40 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M38.5437 20.4776C40.4854 21.5987 40.4854 24.4013 38.5437 25.5224L4.36885 45.2532C2.42714 46.3743 -1.06801e-06 44.973 -9.70001e-07 42.7309L7.54926e-07 3.26914C8.52931e-07 1.02704 2.42714 -0.374268 4.36885 0.74678L38.5437 20.4776Z" fill={playState === "play"? "#FFB72C" : "#DFDBCE"}/>
                </svg>
            }
            {playState === "pause"&&
                <svg id='pauseIcon'width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="15" height="40" rx="4" fill={playState === "pause"? "#FFB72C" : "#DFDBCE"}/>
                    <rect x="25" width="15" height="40" rx="4" fill={playState === "pause"? "#FFB72C" : "#DFDBCE"}/>
                </svg>
            }
        </div>
  )
}