import React from 'react'

export default function RecButton({ recordState, handleRecord }) {
    return (
        <div onClick={handleRecord}>
            <svg  id='recordIcon' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="20" cy="20" r="20" fill={recordState? "#ff2c2c" : "#DFDBCE"}/>
            </svg>
        </div>
    )
}

