const musicalScale = {
    // 2 for 1 wholetone & 1 for 1 halftone 
    chromatic:  [0, 1, 2, 3, 4, 5, 6, 7 ,8, 9, 10, 11],  // base C => C#, D, D#, E, F, F#, G, G#, A, A#, B, C
    major:      [0, 2, 4, 5, 7, 9, 11],  // base C =>  D,   E,   F,  G,  A,   B,   C
    minor:      [0, 2, 3, 5, 7, 8, 10],  // base C =>  D,   D#,  F,  G,  G#,  A#,  C
    phrygian:   [0, 1, 3, 5, 7, 8, 10],  // base C =>  C#,  D#,  F,  G,  G#,  A#,  C
    lydian:     [0, 2, 4, 6, 7, 9, 11],  // base C =>  D,   D#,  F,  G,  G#,  A#,  C
    mixolydian: [0, 2, 4, 5, 7, 9, 10],  // base C =>  D,   E,   F,  G,  A,   A#,  C
    locrian:    [0, 1, 3, 5, 6, 8, 10],  // base C =>  C#,  D#,  F,  F#, G#,  A#,  C
}

export default musicalScale