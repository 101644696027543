import seqStore from '../../store/seqStore'

export default function GateLength() {
    const rootNote = Number(seqStore((state) => state.sequenceSettings.root))
    const setRootNote = seqStore((state) => state.setRoot)
    
    const rootArray = ["C", "C#", "D", "D#", "E", "F", "F#", "G", "G#", "A", "A#", "B"]

    // GATE LENGTH CONTROLS
    const handleRootNote = (e) => {
        const value = e.target.value
        setRootNote(value)
        console.log('rootnote ', value)
    }

    const resetRootNote = () => {
        setRootNote(0)
    }

    return (
        <div id='rootNoteInput'>
            <label htmlFor="root note">Root <span>{rootArray[rootNote]}</span></label>
            <input id='enabledInput' type="range" min={0} max={11} step='1' value={rootNote} onChange={e => handleRootNote(e)} onDoubleClick={resetRootNote}/>
        </div>
    )
}
