import SaveAltRoundedIcon from '@mui/icons-material/SaveAltRounded'
import seqStore from '../../store/seqStore'

export default function SavePreset() {
    const sequence = seqStore((state) => state.sequence)
    const sequenceQ = seqStore((state) => state.sequenceQ)
    const sequenceSettings = seqStore((state) => state.sequenceSettings)
    const tempo = seqStore((state) => state.tempo)
    const setTempo = seqStore((state) => state.setTempo)
    const setSequenceSettings = seqStore((state) => state.setSequenceSettings)
    const setSequence = seqStore((state) => state.setSequence)
    const setSequenceQ = seqStore((state) => state.setSequenceQ)

    // Function to serialize preset data
    const serializeState = () => {
        const stateToSave = {
          sequence: sequence,
          sequenceQ: sequenceQ,
          sequenceSettings: sequenceSettings,
          tempo: tempo,
        }
        return JSON.stringify(stateToSave)
    }

    // Function to save the entire state to a file
    const saveStateToFile = () => {
        const stateJSON = serializeState()
        const blob = new Blob([stateJSON], { type: 'application/json' })
        const fileName = 'Meloclidean_Settings.json'
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.download = fileName
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        URL.revokeObjectURL(url) 
    }

    // Function to load the entire state from a file
    const loadStateFromFile = (file) => {
        const reader = new FileReader()
        reader.onload = (event) => {
            try {
                const stateData = JSON.parse(event.target.result)
                console.log("data from file: ", stateData)
                // Update global store with loaded state
                setTempo(stateData.tempo)
                setSequenceSettings(stateData.sequenceSettings)
                stateData.sequence.forEach((index, note)=> {
                    setSequence(index, note)
                })
                stateData.sequenceQ.forEach((index, note)=> {
                    setSequenceQ(index, note)
                })
                
            } catch (error) {
                console.error('Error parsing state file:', error)
            }
        }
        reader.readAsText(file)
    }

    // Event handler for file input change
    const handleFileInputChange = (event) => {
        const file = event.target.files[0]
        if (file) {
            loadStateFromFile(file)
            // Revoke the object URL to remove the file
            setTimeout(() => {
                URL.revokeObjectURL(event.target.result)
                console.log('file removed')
            }, performance.now() + 1000)
        }
    }

    return (
        <div>
            <input id='fileInput' type="file" accept=".json" onChange={handleFileInputChange} />
            <button onClick={saveStateToFile}>Save State</button>
        </div>
    )
}
