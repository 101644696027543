import React from 'react'
import { useRef, useEffect } from 'react'
import seqStore from '../../store/seqStore'

export default function Division() {
    const division = seqStore((state) => state.sequenceSettings.division)
    const setDivision = seqStore((state) => state.setDivision)

    const divisionArray = ['1', '2', '4', '8', '16', '32', '64']

    // DIVISION CONTROLS
    const handleDivision = (e) => {
        const value = e.target.value
        setDivision(value)
    }

    const resetDivision = () => {
        setDivision(4)
    }
    
    return (
        <div id='divisionInput'>
            <label htmlFor="root note"><p>Division <span>{divisionArray[division]}</span></p></label>
            <input id='enabledInput' type="range" min={0} max={6} step='1' value={division} onChange={e => handleDivision(e)} onDoubleClick={resetDivision}/>
        </div>
    )
}
