import React from 'react'
import seqStore from '../../store/seqStore'

export default function Length() {
    const length = seqStore((state) => state.sequenceSettings.length)
    const setLength = seqStore((state) => state.setLength)

    // LENGTH CONTROLS
    const handleLength = (e) => {
        const value = e.target.value
        setLength(value)
    }

    const resetLength = () => {
        setLength(16)
    }
    
    return (
        <div id='lengthInput'>
            <label htmlFor="root note">Length <span>{length}</span></label>
            <input id='enabledInput' type="range" min={1} max={32} step='1' value={length} onChange={e => handleLength(e)} onDoubleClick={resetLength}/>
        </div>
    )
}
