import { useState, useEffect, useCallback } from "react"
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import seqStore from "../store/seqStore"
import musicalScale from "../assets/musicalScale"
import PlayButton from "../components/inputs/PlayButton"
import StopButton from "../components/inputs/StopButton"
import Tempo from "../components/inputs/Tempo"
import Division from "../components/inputs/Division"
import Scales from "../components/inputs/Scales"
import MidiChannel from "../components/inputs/MidiChannel"
import MidiOutSelect from "../components/inputs/MidiOutSelect"
import Pulses from "../components/inputs/Pulses"
import Length from "../components/inputs/Length"
import Offset from "../components/inputs/Offset"
import Direction from "../components/inputs/Direction"
import Transpose from "../components/inputs/Transpose"
import Octave from "../components/inputs/Octave"
import DentalSequencer from "../components/DentalSequencer"
import Keyboard from "../components/inputs/Keyboard"
import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import DisplayNote from "../components/DisplayNote"
import RecButton from "../components/inputs/RecButton"
import playheadCounter from "../utils/playheadCounter"
import calcDuration from "../utils/calcDurations"
import genEuclidean from "../utils/genEuclidean"
import findMidiNoteNumber from "../utils/findMidiNoteNumber"
import GateLength from "../components/inputs/GateLength"
import RootNote from "../components/inputs/RootNote"
import SavePreset from "../components/inputs/SavePreset"
import Ratchet from "../components/inputs/Ratchet"

export default function Home() {
    // local states
    const [recordState, setRecordState] = useState(false)
    const [playState, setPlayState] = useState("stop")
    const [prevKeyPress, setPrevKeyPress] = useState(0)
    const [isOptionsOpen, setIsOptionsOpen] = useState(false)
    const [isEuclideanOpen, setIsEuclideanOpen] = useState(false)
    const [midiNumber, setMidiNumber] = useState(null)
    const [prevNote, setPrevNote] = useState(0)
    const [MIDIOutput, setMIDIOutput] = useState(null)
    const [tickDuration, setTickDuration] = useState(null)
    const [stepDuration, setStepDuration] = useState(null)
    const [gateLength, setGateLength] = useState(null)
    const [isAnimation, setIsAnimation] = useState(true)

    // store states
    const pattern = seqStore((state) => state.sequenceSettings.pattern)
    const setPattern = seqStore((state) => state.setPattern)
    const tempo = Number(seqStore((state) => state.tempo))
    const midiSequence = seqStore((state) => state.midiSequence)
    const sequence = seqStore((state) => state.sequence)
    const sequenceQ = seqStore((state) => state.sequenceQ)
    const setNote = seqStore((state) => state.setNote)
    const setQNote = seqStore((state) => state.setQNote)
    const seqRatchet = seqStore((state) => state.sequenceSettings.seqRatchet)
    const rootNote = Number(seqStore((state) => state.sequenceSettings.root))
    const length = Number(seqStore((state) => state.sequenceSettings.length))
    const scale = Number(seqStore((state) => state.sequenceSettings.scale))
    const pulses = Number(seqStore((state) => state.sequenceSettings.pulses))
    const offset = Number(seqStore((state) => state.sequenceSettings.offset))
    const ratchet = Number(seqStore((state) => state.sequenceSettings.ratchet))
    const gatePercentage = Number(seqStore((state) => state.sequenceSettings.gateLength))
    const transSemi = Number(seqStore((state) => state.sequenceSettings.transpose))
    const transOctave = Number(seqStore((state) => state.sequenceSettings.octave))
    const division = Number(seqStore((state) => state.sequenceSettings.division))
    const direction = Number(seqStore((state) => state.sequenceSettings.direction))
    const midiCh = Number(seqStore((state) => state.sequenceSettings.midiCh))
    const playhead = Number(seqStore((state) => state.sequenceSettings.playhead))
    const setPlayhead = seqStore((state) => state.setPlayhead)

    // various variables
    const NOTE_ON = '0x9' //MIDI message code on chan1
    const NOTE_OFF = '0x8' //MIDI message code on chan1
    const CLOCK = '0xF8'
    const START = '0xFA'
    const CONTINUE = '0xFB'
    const STOP = '0xFC'
    const CC = '0xB' // MIDI message code for CONTROL CHANGE + MIDICH[midiCh]
    const ALLSOUNDOFF = '0x78'
    const MIDICH = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
    const scaleNames = ['chromatic', 'major', 'minor', 'phrygian', 'lydian', 'mixolydian', 'locrian']

    //const note16th = tickDuration * 6         // A 16th measure === 1/4 beat === 6 ticks
    //const note8th = tickDuration * 12         // a 8th measure === 1/2 beat === 12 ticks
    //const note4th = tickDuration * 24         // a quarter measure === 1 beat === 24 ticks
    //const noteHalf = tickDuration * 48        // a half measure === 2 beats === 48 ticks
    //const noteWhole = tickDuration * 96       // a measure === 4 beats === 96 ticks

    // Find tick duration and step duration
    useEffect(() => {
        let tempTick = null
        let tempStep = null

        if (division !== null && tempo !== null) {
            tempTick = 60000 / (tempo * 24)

            if (tempTick !== null) {
                // Calculate step duration based on division
                switch (division) {
                    case 6:
                        tempStep = tempTick * 1.5
                        break
                    case 5:
                        tempStep = tempTick * 3
                        break
                    case 4:
                        tempStep = tempTick * 6
                        break
                    case 3:
                        tempStep = tempTick * 12
                        break
                    case 2:
                        tempStep = tempTick * 24
                        break
                    case 1:
                        tempStep = tempTick * 48
                        break
                    case 0:
                        tempStep = tempTick * 96
                        break
                    default:
                        break
                }
            } 

            // Update state only if tempo or division has changed
            if (tempTick !== tickDuration || tempStep !== stepDuration) {
                setTickDuration(tempTick)
                setStepDuration(tempStep)
                setGateLength((tempStep / 100) * gatePercentage)
            }
            //console.log('step duration: ', tempStep, ' | ', 'tick duration: ', tempTick, ' | ', 'gate length: ', (tempStep / 100) * gatePercentage)
        }
    }, [tempo, division, tickDuration, stepDuration, gatePercentage])

    // Find gate duration
    useEffect(()=>{
        const fragStepDuration = stepDuration / 100 // 1% of the step duration
        const length = fragStepDuration * gatePercentage
        setGateLength(length)
    }, [gatePercentage, gateLength, stepDuration])

    /*// send CLOCK, START, STOP, CONTINUE
    useEffect(() => {
        let intervalTick
        let playSent = false
    
        const clockSync = () => {
            intervalTick = setInterval(() => {
                MIDIOutput.send([CLOCK])
            }, tickDuration)
        }

        const startSync = () => {
            if (!playSent) {
                MIDIOutput.send([START])
                console.log('start synchro')
                playSent = true
                clockSync()
            } else {
                return
            }
        }
    
        const pauseSync = () => {
            MIDIOutput.send([CONTINUE])
            console.log('pause synchro')
            clearInterval(intervalTick)
        }
    
        const stopSync = () => {
            MIDIOutput.send([STOP])
            console.log('stop synchro')
            clearInterval(intervalTick)
            playSent = false
        }
    
        if (MIDIOutput) {
            if (playState === 'play') {
                startSync()
            } else if (playState === 'pause') {
                pauseSync()
            } else if (playState === 'stop') {
                stopSync()
            }
        }
    
        return () => {
            clearInterval(intervalTick)
        }
    }, [playState, tempo, MIDIOutput])*/

    // update the euclidean pattern when euclidean parameters changed
    useEffect(()=> {
        const newPattern = genEuclidean(pulses, length, offset)
        setPattern(newPattern)
    },[pulses, length, offset])

    // interval mechanics
    useEffect(()=> {
        let intervalId
        let tempPlayhead

        const playSequence = () => {
            if (MIDIOutput) {
                // if sequencer was in a stop mode the next playhead will be 0
                if (playhead === -1) {
                    tempPlayhead = 0
                    setPlayhead(tempPlayhead)
                } else {
                    // move playhead ahead
                    tempPlayhead = playheadCounter(playhead, direction, length)
                    setPlayhead(tempPlayhead)
                }
                
                // if the step has an active pulse
                if (pattern[tempPlayhead] === 1) {
                    // Define MIDI messages
                    const messageON = NOTE_ON + MIDICH[midiCh]
                    const messageOFF = NOTE_OFF + MIDICH[midiCh]
                    let note

                    if (scale !== 0) {
                        note = sequenceQ[tempPlayhead] + (transOctave * 12) + transSemi
                        note = Math.max(0, Math.min(127, note))
                    } else {
                        note = sequence[tempPlayhead] + (transOctave * 12) + transSemi
                        note = Math.max(0, Math.min(127, note))
                    }

                    const velocity = 0x7f // 127

                    // Send initial note ON message
                    MIDIOutput.send([messageON, note, velocity])

                    if (seqRatchet[tempPlayhead] !== 0) {
                        // Calculate additional note ON and note OFF messages for ratcheting
                        for (let i = 1; i <= seqRatchet[tempPlayhead]; i++) {
                            const delay = (stepDuration / (seqRatchet[tempPlayhead] + 1)) * i
                            const timeON = performance.now() + delay
                            const timeOFF = timeON + (stepDuration / (seqRatchet[tempPlayhead] + 1))

                            // Send additional note ON message
                            MIDIOutput.send([messageON, note, velocity], timeON)
                            // Send corresponding note OFF message
                            MIDIOutput.send([messageOFF, note, 0], timeOFF)
                        }
                    }
                    // Send final note OFF message after the full gate length
                    MIDIOutput.send([messageOFF, note, 0], performance.now() + gateLength)
                    setPrevNote(note)
                } else {
                    return
                }
            } else {
                return
            }
        }
        
        if (playState === 'play') {
            //stepDuration = calcDuration(tempo, division)

            // play sequence
            intervalId = setInterval(playSequence, stepDuration)
        } else if (playState === 'stop') {
            return
        }
        return () => {
            // clear interval on unmount
            clearInterval(intervalId)
        }
    //},[MIDIOutput, tempo, division, length, playhead, direction, playState, transOctave, transSemi, pattern, gateLength])
    },[MIDIOutput, playhead, playState, pattern, ratchet])


    // calculate notes in the selected scale based on the root note
    const calculateScaleNotes = () => {
        // select the scale
        const nameOfScale = scaleNames[scale]
        const scaleIntervals = musicalScale[nameOfScale]
        console.log('Scale Intervals:', scaleIntervals)
        if (scale !== 0) { // any scale except chromatic
            // Calculate scale notes based on the root reference and the intervals
            const scaleNotes = scaleIntervals.map(interval => (rootNote + interval) % 12) 
            console.log('Calculated Scale Notes:', scaleNotes)
            return scaleNotes
        }
    }

    const applyScaleModeToSequence = () => {
        const scaleNotes = calculateScaleNotes()
        //console.log('Root Note:', rootNote)
        //console.log('Scale Notes:', scaleNotes)
        const quantizedSequence = sequence.map(note => {
            const noteIndex = note % 12 // Get the note index within the octave range
            const closestNote = findClosestNoteInScale(noteIndex, scaleNotes) // Find the closest note in the scale
            return closestNote
        })

        // Restore the original octave
        const quantizedSequenceWithOctave = quantizedSequence.map((note, index) => {
            const originalOctave = Math.floor(sequence[index] / 12) // Calculate the original octave
            return note + (originalOctave * 12) // Add the original octave to the quantized note
        })

        //console.log('Quantized Sequence with Octave: ', quantizedSequenceWithOctave)

        // Update the melody with the quantized melody
        quantizedSequenceWithOctave.forEach((note, index) => {
            setQNote(index, note)
        })
    }

    const modeIntervals = {
        major: [4, 7, 11], // Major Third, Perfect Fifth, Major Seventh
        minor: [3, 7, 10], // Minor Third, Perfect Fifth, Minor Seventh
        phrygian: [1, 8, 10], // Minor Second, Minor Sixth, Minor Seventh
        lydian: [4, 6, 11], // Major Third, Augmented Fourth, Major Seventh
        mixolydian: [4, 7, 10], // Major Third, Perfect Fifth, Minor Seventh
        locrian: [1, 6, 10] // Minor Second, Diminished Fifth, Diminished Seventh
    }
    
    // Function to find the closest note in the scale
    const findClosestNoteInScale = (noteIndex, scaleNotes, mode) => {
        let closestNote = scaleNotes[0]
        let minDifference = Math.abs(noteIndex - closestNote)
    
        for (let i = 1; i < scaleNotes.length; i++) {
            const difference = Math.abs(noteIndex - scaleNotes[i])
            if (difference < minDifference) {
                minDifference = difference
                closestNote = scaleNotes[i]
            } else if (difference === minDifference && modeIntervals[mode]?.includes(scaleNotes[i])) {
                closestNote = scaleNotes[i]
            }
        }
        return closestNote
    }

    // note quantizer
    useEffect(() => {
        if (sequence && scale && musicalScale) {
            applyScaleModeToSequence()
        }
    }, [scale, rootNote])

    // handle play button
    const handlePlay = () => {
        if (playState === "play") {
            setPlayState("pause")
        } else {
            setPlayState("play")
            setRecordState(false)
        }
    }
    
    // handle stop button
    const handleStop = () => {
        if (playState === "stop") {
            if (recordState) { 
                setRecordState(false)
                setPlayhead(-1)
            }
        } else {
            setPlayState("stop")          
            setPlayhead(-1)// reset playhead
            MIDIOutput.send([CC + MIDICH[midiCh], ALLSOUNDOFF, 0x00])// send note OFF
        }
    }

    // handle record state
    const handleRecord = () => {
        if (playState === "play") setRecordState(false)
        else if (recordState) {
            setRecordState(false)
            setPlayhead(-1)
        } else {
            setRecordState(true)
            setPlayhead(0)
        }
    }
    
    // handle options open Menu
    const handleOptionsMenu = () => {
        setIsOptionsOpen(!isOptionsOpen)
    }
    
    // handle euclidean open Menu
    const handleEuclideanMenu = () => {
        setIsEuclideanOpen(!isEuclideanOpen)
    }

    // handle midi out device selection
    const selectMidiOutDevice = useCallback((selectedDevice) => {
        setMIDIOutput(selectedDevice)
        console.log('selected midi: ', selectedDevice)
        if (selectedDevice.id) {
            sessionStorage.setItem('storedMidiDevice', selectedDevice.id)
        }
    }, [])

    // handler the key 'Space'
    useEffect(() => {
        window.addEventListener("keydown", handleSpaceKey)
    
        return () => {
          window.removeEventListener("keydown", handleSpaceKey)
        }
      }, [playState])
      
    const handleSpaceKey = (event) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.key === ' ') {
            // Implement debounce mechanism for Space key press
            const currentTime = Date.now()
            if (currentTime - prevKeyPress < 300) {
            // Quick double press detected, stop the sequencer
                setPlayState('stop')
                // reset playhead
                setPlayhead(-1)
                // send note OFF
                MIDIOutput.send([CC + MIDICH[midiCh], ALLSOUNDOFF, 0x00])
            } else {
            // Single press, toggle between play and pause
                setPlayState((prevState) => (prevState === 'play' ? 'pause' : 'play'))
            }
            // Update last key press time
            setPrevKeyPress(currentTime)
        }
    }

    // toggle mouth animation
    const handleAnimation = () => {
        setIsAnimation(!isAnimation)
    }

    return (
        <div className="App">
            <section id='firstRowSection'>
                <div id='heroWrapper'>
                    <h1>meloclidean</h1>
                    <h3>An euclidean sequencer in the web <br></br>with midi IN & OUT and a great dentition.</h3>
                </div>
                <DisplayNote playState={playState} MIDIOutput={MIDIOutput} stepDuration={stepDuration}/>
                <div id='transportWrapper'>
                    <PlayButton playState={playState} handlePlay={handlePlay} handleSpaceKey={handleSpaceKey}/>
                    <StopButton playState={playState} handleStop={handleStop}/>
                    <RecButton recordState={recordState} handleRecord={handleRecord} />
                    <Tempo />
                </div>
            </section>
            <section id='secondRowSection'>
                <DentalSequencer playhead={playhead} playState={playState} MIDIOutput={MIDIOutput} gateLength={gateLength} isAnimation={isAnimation}/>
                {isAnimation ?            
                    <PauseRoundedIcon id='animationIcon'onClick={handleAnimation}/>
                    :
                    <PlayArrowRoundedIcon id='animationIcon'onClick={handleAnimation}/>
                }
            </section>
            <section id='thirdRowSection'>
                <div id='euclideanCol'> 
                    <div className="section_title" onClick={handleEuclideanMenu}>
                        <h4>RHYTHM GENERATOR</h4>
                        {isEuclideanOpen? 
                            <CloseRoundedIcon className='closeIcon'/> 
                            : 
                            <ExpandMoreRoundedIcon className='expandIcon'/>
                        }
                    </div>
                    <div id={isEuclideanOpen? 'euclideanInputRowOpen' : 'euclideanInputRowClose'}>
                        <Pulses />
                        <Length />
                        <Offset />
                        <GateLength />
                        <Division />
                        <Direction />
                    </div>
                </div>
                <div id='sequencerCol'> 
                    <div className="section_title" onClick={handleOptionsMenu}>
                        <h4>SEQUENCE OPTIONS</h4>
                        {isOptionsOpen? 
                            <CloseRoundedIcon className='closeIcon'/> 
                            : 
                            <ExpandMoreRoundedIcon className='expandIcon'/>
                        }
                    </div>
                    <div id={isOptionsOpen? 'optionsInputRowOpen' : 'optionsInputRowClose'}>
                        <Transpose />
                        <Octave />
                        <RootNote />
                        <Scales />
                        <MidiOutSelect onSelect={selectMidiOutDevice} />
                        <MidiChannel />
                        <SavePreset />
                    </div>
                </div>
            </section>
            <section id='fourthRowSection'>
                <Keyboard recordState={recordState} MIDIOutput={MIDIOutput} gateLength={gateLength}/>
            </section>
        </div>
    )
}