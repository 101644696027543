import React from 'react'
import seqStore from '../../store/seqStore'

export default function Direction() {
    const direction = seqStore((state) => state.sequenceSettings.direction)
    const setDirection = seqStore((state) => state.setDirection)
   
    const directionArray = ['Fwd', 'Bwd', 'Alt', 'Rdm']

    // DIRECTION CONTROLS
    const handleDirection = (e) => {
        const value = e.target.value
        setDirection(value)
    }

    const resetDirection = () => {
        setDirection(0)
    }
    
    return (
        <div id='directionInput'>
            <label htmlFor="root note"><p>Direction <span>{directionArray[direction]}</span></p></label>
            <input id='enabledInput' type="range" min={0} max={3} step='1' value={direction} onChange={e => handleDirection(e)} onDoubleClick={resetDirection}/>
        </div>
    )
}
