import React from 'react'

export default function StopButton({ playState, handleStop }) {
    return (
        <div onClick={handleStop}>
            <svg id='stopIcon' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="40" height="40" rx="4" fill={playState === "stop"? "#FFB72C" : "#DFDBCE"}/>
            </svg>
        </div>

    )
}
