import React from 'react'
import seqStore from '../../store/seqStore'

export default function Tempo() {
    const tempo =seqStore((state) => state.tempo)
    const setTempo = seqStore((state) => state.setTempo)
    const sync = seqStore((state) => state.sequenceSettings.sync)

    // TEMPO CONTROLS
    const handleTempo = (e) => {
        const value = e.target.value
        setTempo(value)
    }

    const resetTempo = () => {
        setTempo(120)
    }
    

    return (
        <div id='tempoInput'>
            <label htmlFor='sequencer_tempo' id={sync? 'disabledLabel' : null} ><p>Tempo: <span>{tempo}bpm</span></p></label>
            <input disabled={sync} id={sync? 'disabled' : 'enabledInput'} type="range" min={30} max={300} step='1' value={tempo} onChange={e=> handleTempo(e)} onDoubleClick={resetTempo}/>
        </div> 
    )
}
