import React, { useEffect, useState } from 'react'
import seqStore from '../../store/seqStore'
import fullScale from '../../assets/fullScale'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'

export default function Keyboard({ recordState, MIDIOutput, gateLength }) {

    const sequence = seqStore((state) => state.sequence)
    const setNote = seqStore((state) => state.setNote)
    const length = seqStore((state) => state.sequenceSettings.length)
    const midiCh = Number(seqStore((state) => state.sequenceSettings.midiCh))
    const transSemi = seqStore((state) => state.sequenceSettings.transpose)
    const transOctave = seqStore((state) => state.sequenceSettings.octave)
    const playhead = seqStore((state) => state.sequenceSettings.playhead)
    const setPlayhead = seqStore((state) => state.setPlayhead)
    const [isPressed, setIsPressed] = useState(null)

    const MIDICH = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', 'a', 'b', 'c', 'd', 'e', 'f']
    const midiNoteToReadable = (noteNumber) => {
        const noteNames = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B']
        const octave = Math.floor(noteNumber / 12) - 1 // MIDI note numbering starts from C-1
        const note = noteNames[noteNumber % 12]
        return { note, octave }
    }

    const handleKeyDown = (keyNote) => {
        setIsPressed(keyNote)
        if (recordState) {
            const nextPlayhead = (playhead + 1) % length
            const messageON = '0x9' + MIDICH[midiCh]
            const messageOFF = '0x8' + MIDICH[midiCh]
            const note = keyNote
            const velocity = 0x7f

            MIDIOutput.send([messageON, note, velocity])
            setNote(playhead, note) //save pressed note
            MIDIOutput.send([messageOFF, note, 0], performance.now() + gateLength) // gate duration timeout
            setPlayhead(nextPlayhead)
        } else {
            return
        }
    }

    const handleKeyUp = (keyNote) => {
        if (recordState) {
            setIsPressed(null)
        } else {
            return
        }
    }

    // build keyboard
    const keys = fullScale.map((item, index) => {
        const keyClass = item.includes('#') ? 'keySharp' : 'keyFlat'
        const pressedClass = item === isPressed ? 'keyPressed' : ''
        return (
            <div 
                key = {`key${item}`} 
                className = {`key ${keyClass} ${pressedClass}`}
                onMouseDown = {() => handleKeyDown(index)}
                onMouseUp = {() => handleKeyUp(index)}
                onMouseLeave = {() => handleKeyUp(index)}
                data-value = {index}
            >
                <p className = 'keyText'>{item}</p>
            </div>
        )
    })

    // handle midi INPUT message
    /*useEffect(() => {
        if (recordState && MIDIInput !== "no-device") {
            if (MIDIInput) {
                MIDIInput.onmidimessage = getMIDIMessage
            } else {
                console.error('MIDI Input device not available.')
            }
        } else {
            return
        }
        console.log('midi input selection: ', MIDIInput)
    }, [MIDIInput, recordState])
    
    const getMIDIMessage = (event) => {
        try {
            const statusByte = event.data[0]
            const messageType = statusByte & 0xf0 // Mask the status byte to get the message type
        
            if (messageType === 0x90) { // Note On message
                const midiNote = event.data[1]
                const midiVelocity = event.data[2]
                
                MIDIOutput.send(['0x9' + midiCh, midiNote, midiVelocity])
                
            } else if (messageType === 0x80) { // Note Off message
                const midiNote = event.data[1]
                console.log(midiNote)
                const midiVelocity = event.data[2]
                
                // send note OFF
                MIDIOutput.send(['0x8' + midiCh, midiNote, midiVelocity])
                
                const tempNote = midiNoteToReadable(midiNote)
                setNote(playhead, tempNote.note, tempNote.octave)

                const nextPlayhead = (playhead + 1) % length
                setPlayhead(nextPlayhead)
            }
        } catch (error) {
            console.error('Error processing MIDI message:', error)
        }
    }*/
    

    return (
        <div id='sectionWrapper'> {/* row display + keyboard */}
            <div id='keyboardOverflow'>
                    {/* <ChevronLeftIcon id='chevronLeftIcon'/> */}
                <div id='keyboardWrapper'>
                    {keys}
                </div>
                    {/* <ChevronRightIcon id='chevronRightIcon'/> */}
            </div>
        </div>
    )
}
